@import 'styles/variables';
@import 'styles/legal-page-style';

#privacy-policy {

    .text-content:not(:last-child)::after {
        content: "";
        display: block;
        width: 100%;
        margin: 0px auto;
        height: 0px !important;
        background-color: rgb(231, 231, 231);
    }

    &.skeleton-policy::after {
        background-color: unset !important;
    }

    .legal-page-skeleton {
        border-top: unset !important;
    }

    &.skeleton-policy {
        .header {
            border-block: none;
        }
    }

    .skeleton-loading {
        display: flex;
        margin-top: $regularPad;
        background-color: $ghost-white !important;

        &.text-content {
            height: 96px;
        }

        &.text-content-2 {
            height: 192px;
        }

        &.text-content-3 {
            height: 160px;
        }

        &.header-title {
            width: 200px !important;
            height: 36px;
            position: relative;
            left: 0px;
            margin-top: 0px;

            @media (max-width: 1400px) {
                left: -280px;
            }

            @media (max-width: 800px) {
                margin-right: 220px;
            }

            @media (max-width: 700px) {
                margin-right: 120px;
            }

            @media (max-width: 600px) {
                margin-right: 60px;
            }

            @media (max-width: 560px) {
                margin-right: -50px;
            }

            @media (max-width: 450px) {
                margin-right: -150px;
            }
        }


        &.last-update {
            height: 24px;
            width: 280px;
            margin-top: $regularPad;
            margin-left: auto;
        }

        &.introduction-title,
        &.collection-title,
        &.analytics-title,
        &.security-title {
            height: $mediumPad;
            width: 200px;
        }

        &.introduction-content {
            height: 820px;

            @media (max-width: $tablet) {
                height: 850px;
            }

            @media (max-width: $phone) {
                height: 1450px;
            }
        }

        &.collection-content {
            height: 585px;

            @media (max-width: $tablet) {
                height: 600px;
            }

            @media (max-width: $phone) {
                height: 930px;
            }
        }

        &.how-use-information-title,
        &.unsubscribe-title,
        &.stored-title {
            height: $mediumPad;
            width: 400px;
        }

        &.how-use-information-content {
            height: 1500px;

            @media (max-width: $tablet) {
                height: 1670px;
            }

            @media (max-width: $phone) {
                height: 2800px;
            }
        }

        &.insurance-life-title,
        &.personal-title,
        &.eu-title,
        &.ca-title {
            height: $mediumPad;
            width: 780px;

            @media (max-width: $tablet) {
                width: 400px;
            }
        }

        &.insurance-life-content {
            height: 830px;

            @media (max-width: $tablet) {
                height: 980px;
            }

            @media (max-width: $phone) {
                height: 1578px;
            }
        }

        &.analytics-content {
            height: 440px;
        }

        &.remarketing-title &.sharing-title,
        &.right-title,
        &.protection-title {
            height: $mediumPad;
            width: 300px;
        }

        &.remarketing-content {
            height: 1670px;

            @media (max-width: $tablet) {
                height: 1670px;
            }

            @media (max-width: $phone) {
                height: 2720px;
            }
        }

        &.sharing-content,
        &.unsubscribe-content {
            height: 400px;

            @media (max-width: $tablet) {
                height: 485px;
            }

            @media (max-width: $phone) {
                height: 740px;
            }
        }

        &.stored-content {
            height: 140px;
        }

        &.security-content {
            height: 240px;
        }

        &.right-content {
            height: 128px;
        }

        &.protection-content {
            height: 224px;
        }

        &.personal-content {
            height: 370px;
        }

        &.eu-content {
            height: 2850px;
        }

        &.ca-content {
            height: 820px;
        }
    }
}