@use '@chicksgroup/styles/variables' as v-global;
@use '@chicksgroup/styles/mixins' as m-global;
@import 'styles/variables';

#locations {
    
    .skeleton-loading {
        display: flex;
        margin-top: $regularPad;
        background-color: $ghost-white !important;
        width: 770px;

        @media (max-width: $desktop) {
            margin-top: 25px;
        }

        @media (max-width: 800px) {
            width: 500px;
            display: flex;
        }

        @media (max-width: 600px) {
            width: 400px;
        }

        @media (max-width: 400px) {
            width: 300px;
        }

        &.offer-card-skeleton {
            position: relative;
        }

        &.description-1 {
            width: 590px;
            height: 24px;

            @media (max-width: 1600px) {
                width: 770px;
                height: 24px;
            }

            @media (max-width: 768px) {
                width: auto;
                height: 24px;
            }

            @media (max-width: 372px) {
                width: auto !important;
                height: 48px;
            }
        }

        &.title-1 {
            width: 490px;
            height: 48px;
            background-color: $ghost-white !important;

            @media (max-width: 1600px) {
                width: 500px !important;
                height: 48px;
            }

            @media (max-width: 768px) {
                width: auto !important;
                height: 36px;
            }

            @media (max-width: 372px) {
                width: auto !important;
                height: 72px;
            }
        }

        &.offer-card-title {
            width: 330px;
            height: 48px;

            @media (max-width: 1600px) {
                width: 300px;
            }
        }

        &.offer-card-description {
            width: 850px;
            height: 32px;

            @media (max-width: 1600px) {
                width: 770px;
                height: 64px;
            }

            @media (max-width: 798px) {
                width: auto;
                height: 64px;
            }

            @media (max-width: 380px) {
                width: 300px;
                height: 64px;
            }
        }


        &.card {
            width: 770px;
            height: 164px;
            background-color: #eee;

            @media (max-width: 570px) {
                width: 500px;
                height: 456px;
            }

            >* {
                display: none !important;
            }
        }

        &.text-1 {
            margin-left: 530px;
            width: 230px;
            height: 24px;

            @media (max-width: 1000px) {
                margin-left: 450px;
            }

            @media (max-width: 700px) {
                margin-left: 100px;
            }
        }

        &.text-content-1 {
            height: 80px;
        }

        &.title-2 {
            width: 230px;
            height: 30px;
        }
    }

    .truncate-multiline {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5em;
        max-height: 4.5em;
        white-space: normal;
        word-break: break-word;
    }

    p:has(br) {
        display: none;
    }

    p {
        margin-bottom: 0rem;
    }

    .section-container {
        @include m-global.container-fluid(1400px, true);
        @include m-global.margin-x(auto);
        @include m-global.padding-x(20px, false, true);
    }

    .map-modal-container {
        @include m-global.fixedOffset(0px, true, true);
        @include m-global.size(100vw, 100vh, false);
        background-color: rgba(0, 0, 0, 0.3);
        @include m-global.flex(center, center, row, true);
        z-index: 1000;
    }

    .map-modal-content {
        @include m-global.size(648px, 516px, true);
        max-width: 800px;
        max-height: 600px;
        background: var(--cg-grey-100);
        padding-top: 56px;
        padding-bottom: 16px;
        @include m-global.padding-x(24px, false, true);
        @include m-global.flex(center, center, row, true);
        overflow: hidden;
        animation: scaleIn 0.3s ease;
        box-shadow: none;
        margin: 0;
        border-radius: 4px;
    }

    .map-modal-content iframe {
        @include m-global.size(600px, 440px, true);
        border: none;
    }

    .map-modal-content {
        position: relative;
    }

    .modal-close-button {
        position: absolute;
        right: 16px;
        top: 10px;
        background: none;
        border: none;
        font-size: 24px;
        color: var(--cg-grey-800);
        cursor: pointer;
        z-index: 100;
        padding: 8px;
        line-height: 1;
    }

    @media (max-width: 768px) {
        .map-modal-content {
            @include m-global.size(608px, 495px, true);
            padding-top: 56px;
            padding-bottom: 16px;
            @include m-global.padding-x(24px, false, true);
        }

        .map-modal-content iframe {
            @include m-global.size(560px, 419px, true);
        }

        .modal-close-button {
            @include m-global.getPositions(0px, true, true);
            right: 18px;
            top: 10px;
        }
    }

    @media (max-width: 376px) {
        .map-modal-content {
            @include m-global.size(336px, 312px, true);
            padding-top: 56px;
            padding-bottom: 16px;
            @include m-global.padding-x(8px, false, true);
        }

        .map-modal-content iframe {
            @include m-global.size(320px, 240px, true);
        }

        .modal-close-button {
            @include m-global.getPositions(0px, true, false);
            right: -295px;
            top: 7px;
        }
    }

    #page-content-image img {
        display: block;
        width: 100%;
        max-height: 132px;
        @include m-global.size(100%, 132px);
        object-fit: cover;
        border-radius: 4px;
    }

    .locations-section .locations-list .location-card .image-container #page-content-image img {
        @include m-global.size(182px, 132px, true);
        object-fit: cover;
        border-radius: 4px;
    }

    .modal-container {
        @include m-global.fixedOffset(0, true, true);
        @include m-global.size(100%, 100%, true);
        @include m-global.flex(center, center, row, true);
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        border-radius: 4px;
    }

    .modal-backdrop {
        --bs-backdrop-bg: none !important;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: -1 !important;
    }

    .swiper-container {
        @include m-global.size(100%, 80vh, true);
        @include m-global.flex(center, center, row, true);
        overflow: hidden;
        position: relative;
    }

    .swiper-wrapper {
        @include m-global.flex(center, row, true);
    }

    .swiper-slide {
        @include m-global.flex(center, center, row, true);
        @include m-global.size(100%, 100%, true);
        transform: scale(0.8);
        opacity: 0.5;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    .swiper-slide-active {
        transform: scale(1);
        opacity: 1;
    }

    .locationn-card-image {
        display: block;
        @include m-global.size(648px, 680px, true);
        object-fit: cover;
        border-radius: 4px;
    }

    .carousel-image {
        display: block;
        @include m-global.container-fluid(100%, true);
        object-fit: cover;
        border-radius: 4px;
    }

    .swiper-button-next,

    .swiper-button-prev {
        color: var(--cg-grey-1100);
        @include m-global.square(24px, true);
        border-radius: 50%;
        @include m-global.flex(center, center, row, true);
        transition: background 0.3s ease;
        position: absolute;
        top: calc(50% + 20px);
        transform: translateY(-50%);
        z-index: 10;
        opacity: 1;
    }

    .swiper-button-prev:after,

    .swiper-button-next:after {
        font-size: 20px;
        @include m-global.size(11.272px, 20px, true);
        font-family: swiper-icons;
        line-height: 1;
        color: var(--cg-grey-1000, #1F1F1F);
        font-weight: bold;
        text-shadow: 0 0 1px currentColor;
    }

    @media (max-width: 376px) {
        .swiper-button-prev {
            left: -2.4%;
        }

        .swiper-button-next {
            right: -2.4%;
        }
    }

    .swiper-pagination-numbers {
        position: absolute;
        bottom: 45px;
        left: 50%;
        transform: translateX(-50%);
        color: var(--cg-grey-1000, #1F1F1F);
        text-align: center;
        font-family: Roboto;
        @include m-global.font(28px, 500, 24px, null, null, null, true);
        z-index: 10000;
        pointer-events: none;
    }

    .swiper-pagination-lines {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        @include m-global.flex(center, center, row, true);
        gap: 8px;
        z-index: 9999;
        pointer-events: none;
    }

    .swiper-pagination-lines .line.active {
        @include m-global.size(20px, 2px, true);
        flex-shrink: 0;
        border-radius: 3px;
        background: var(--cg-grey-800, #525252);
    }

    .swiper-pagination-lines .line {
        @include m-global.size(20px, 2px, true);
        flex-shrink: 0;
        border-radius: 3px;
        background: var(--cg-grey-400, #d3d3d3);

    }

    .closee-button {
        position: absolute;
        @include m-global.getPositions(10px, true, true, false);
        background: none;
        border: none;
        color: var(--cg-grey-1100);
        font-size: 30px;
        cursor: pointer;
        z-index: 20;
        padding: 10px 25px 0 0;
        line-height: 1;
    }

    .modal-content {
        position: relative;
        background-color: var(--cg-grey-100) !important;
        border-radius: 4px;
        @include m-global.padding-y(16px, false, true);
        @include m-global.padding-x(24px, false, true);
        @include m-global.size(648px, 680px, true);
        max-width: 90vw;
        min-width: 300px;
        max-height: 90vh;
        @include m-global.flex(center, center, column, true);
        z-index: 10;
        margin: 0 auto;
    }

    @media (max-width: 768px) {
        .modal-content {
            @include m-global.size(608px, 616px, true);
            @include m-global.padding-y(16px, false, true);
            @include m-global.padding-x(24px, false, true);
            @include m-global.flex(center, center, column, true);
            margin: auto;
            position: relative;
            overflow: hidden;
        }

        .locationn-card-image,

        .modal-container .swiper-slide #page-content-image img {
            @include m-global.size(440px, 332px, true);
            object-fit: contain;
            position: absolute;
            margin: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            justify-content: center;
        }
    }

    @media (max-width: 480px) {
        .modal-content {
            @include m-global.size(336px, 453px, true);
            @include m-global.padding-y(16px, false, true);
            @include m-global.padding-x(24px, false, true);
            @include m-global.flex(center, center, column, true);
            margin: auto;
            position: relative;
            overflow: hidden;
        }

        .locationn-card-image,

        .modal-container .swiper-slide #page-content-image img {
            @include m-global.size(200px, 151px, true);
            object-fit: contain;
            position: absolute;
            margin: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            justify-content: center;
            max-height: none;
        }
    }

    .locationn-card-image,

    .locations-section .locations-list .location-card .image-container img {
        display: block;
        @include m-global.size(648px, 352px, true);
        max-width: 648px;
        object-fit: cover;
        border-radius: 4px;
    }

    .modal-container .swiper-slide {
        @include m-global.flex(center, center, row);
        @include m-global.size(648px, 352px);
        overflow: hidden;
        position: relative;
        border-radius: 0px;
    }

    .modal-container .swiper-slide .locationn-card-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include m-global.square(100%);
        object-fit: contain;
        border-radius: 4px;
        background: var(--cg-grey-100);
    }

    .modal-container #page-content-image img {
        display: block;
        margin: auto;
        @include m-global.size(480px, 352px, true);
        object-fit: contain;
        border-radius: 0px;
        max-height: none;
    }

    .locations-section {
        background-color: var(--cx-primary-200);
        @include m-global.padding-y(60px, false, true);
        padding-bottom: 1px !important;
        @include m-global.padding-x(0px, false, true);

        .truncate-multilinee {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5em;
            max-height: calc(1.5em * 1);
            white-space: normal;
            word-break: break-word;
            @include m-global.margin-y(5px);
        }

        .locations-container {
            @include m-global.flex(column, flex-start, column, true);
            @include m-global.size(calc(1920px - 360px), auto, true);
            margin: 0 auto;
        }

        .locations-header {
            @include m-global.flex(column, flex-start, column, true);

            .page-title {
                color: var(--cx-secondary-600);
                text-align: left;
                @include m-global.font(40px, 700, 48px, 1.6px, var(--cx-secondary-600), null, true);
                font-family: Roboto, sans-serif;
                margin-bottom: 12px !important;
            }


            .page-description {
                color: var(--cg-grey-1000);
                text-align: left;
                @include m-global.font(16px, 400, 24px, 0.24px, var(--cg-grey-1000), null, true);
                @include m-global.font(16px, 400, 24px, 0.24px, var(--cg-grey-1000), null, true);
                font-family: Roboto, sans-serif;
                margin-bottom: 0px !important
            }
        }

        .locations-list {
            @include m-global.flex(flex-start, flex-start, row, true);
            flex-wrap: wrap;
            @include m-global.flex-gap(20px, flex-start, flex-start, row);

            .location-card {
                @include m-global.size(770px, 164px, true);
                @include m-global.flex(center, flex-start, row, true);
                background: var(--cg-grey-100);
                overflow: hidden;
                cursor: pointer;
                @include m-global.padding-y(16px, false, true);
                @include m-global.padding-x(16px, false, true);
                @include m-global.flex-gap(20px, center, flex-start, row);
                border-radius: 12px;

                &:hover {
                    background-color: var(--cx-primary-300);
                }

                &:active {
                    background-color: var(--cx-primary-400);
                }

                .image-container,

                .image-container-right {
                    display: flex;
                    width: 182px;
                    height: 132px;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    background-color: var(--cg-grey-300);
                    overflow: hidden;
                    flex-shrink: 0;

                    img {
                        display: block;
                        width: auto;
                        height: 132px;
                        max-width: 100%;
                        object-fit: cover;
                        border-radius: 4px;
                    }
                }

                .location-card-content {
                    flex: 1 0 0;
                    @include m-global.flex(initial, initial, column, true);
                    @include m-global.flex-gap(20px, initial, initial, column);

                    h2 {
                        @include m-global.font(20px, 500, 24px, null, var(--cg-grey-1100), null, true);
                        font-family: Roboto, sans-serif;
                        align-self: stretch;
                        margin: 0;
                    }

                    .location-card-description {
                        align-self: stretch;
                        @include m-global.font(14px, 400, 16px, 0.035px, var(--cg-grey-800), null, true);
                        margin: 0;
                        max-height: 48px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        white-space: normal;
                    }

                    .location-card-hours {
                        @include m-global.font(14px, 300, 16px, null, var(--cg-grey-800), null, true);
                        white-space: nowrap !important;
                        margin: 0;
                    }

                    .content-wrapper {
                        @include m-global.flex(column, flex-start, true);
                    }
                }
            }
        }
    }

    .location-card-address {
        align-self: stretch;
        @include m-global.font(14px, 400, 16px, 0.035px, var(--cg-grey-800), null, true);
        margin-bottom: 0px !important;
    }

    .locations-section .locations-container {
        @include m-global.flex(column, flex-start, true);
        gap: 40px;
        @include m-global.container-fluid(calc(1920px - 360px), true);
        @include m-global.margin-y(80px);
        @include m-global.padding-x(0px, false, true);
    }

    .location-card-hours {
        font-size: 0.85rem;
        color: var(--cg-grey-600);
        font-weight: normal;
        margin-top: 10px;

        display: flex;
        align-items: center;
        gap: 5px;
    }

    .location-card-hours span {
        display: inline;
        white-space: nowrap;
    }

    .offer-card-arrow {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4px;
        height: 4px;
    }

    .arrow-icon {
        display: flex;
        width: 5px;
        height: 5px;
        padding: 2px 4px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    cds-icon {
        --md-icon-size: 12px;
    }

    .offer-section {
        background-color: var(--cg-grey-100);
        padding: 0 0 80px;
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .offer-container {
            margin: 0 180px;
            max-width: calc(100% - 360px);
        }

        .offer-header {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1550px;
            margin: 0 auto;

            .offer-title {
                font-size: 40px;
                color: var(--cx-secondary-600);
                font-family: Roboto;
                font-weight: 700;
                line-height: 48px;
                letter-spacing: 1.6px;
                margin-bottom: 12px;
            }

            .offer-description {
                font-size: 16px;
                color: var(--cg-grey-1000);
                font-family: Roboto;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0.24px;
            }
        }

        .offer-cards {
            display: grid;
            grid-template-columns: repeat(2, 770px);
            gap: 20px;
            margin: 0 auto;
            justify-items: center;
            max-width: 1700px;
        }
    }

    @media (min-width: 10000px) {
        .locations-section {
            padding: 80px 180px;
        }

        .locations-container {
            max-width: calc(1920px - 360px);
            padding: 80px 0;
            margin: 0 auto;
        }

        .locations-list .location-card,

        .offer-cards .offer-card {
            width: 770px;
            height: 164px;
        }

        .locations-list .location-card .image-container {
            display: flex;
            width: 182px;
            height: 132px;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: var(--cg-grey-300);
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                height: 132px;
                max-width: 182px;
                max-height: 132px;
                object-fit: cover;
                border-radius: 4px;
            }
        }

        .offer-cards {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: center;
        }
    }



    @media (max-width: 768px) {
        .locations-section .locations-container {
            @include m-global.margin-y(0px);
        }

        .locations-section .locations-header {
            padding-top: 60px !important;
            padding-top: 60px !important;
        }

        .offer-section .offer-header {
            @include m-global.padding-x(20px, false, true);
        }

        .offer-section {
            @include m-global.margin-y(60px);
            padding: 0 0 0px;

        }

        .offer-section .offer-cards {
            @include m-global.padding-x(20px, false, true);
        }

        .offer-section .offer-cards .offer-card .offer-card-arrow,

        .locations-section .locations-list .location-card .location-card-arrow {
            @include m-global.flex(center, center, row, true);
            position: absolute;
            @include m-global.getPositions(10px, false, true, false);
            transform: translateY(-50%);
        }

        .locations-header .page-title,

        .offer-header .offer-title {
            @include m-global.font(32px, 500, 36px, 0.48px, null, null, true);
            margin-bottom: 12px !important;
            margin-bottom: 12px !important;
        }

        .locations-header .page-description,

        .offer-header .offer-description {
            @include m-global.font(14px, 400, 24px, 0.014px, null, null, true);
            @include m-global.font(14px, 400, 24px, 0.014px, null, null, true);
        }

        .locations-section {
            @include m-global.padding-x(20px, false, true);
            @include m-global.padding-y(60px, false, true);
        }

        .locations-container {
            @include m-global.container-fluid(calc(100% - 40px), true);
            margin: 0 auto;
            @include m-global.flex-gap(40px, column, flex-start);
        }

        .locations-list {
            @include m-global.flex(column, flex-start, true);
            @include m-global.flex-gap(20px, column, flex-start);
        }

        .locations-list .location-card {
            width: 100%;
            max-width: 100%;
            @include m-global.size(100%, 164px, true);
            @include m-global.padding-y(16px, false, true);
        }

        .locations-list .location-card .image-container {
            width: 100%;
            height: auto;
        }

        .locations-list .location-card .location-card-content {
            @include m-global.flex(column, flex-start, true);
            @include m-global.flex-gap(12px, column, flex-start);
            overflow: hidden;
            max-width: calc(100% - 32px);
        }

        .locations-section .locations-list .location-card .location-card-content .location-card-description {
            @include m-global.font(14px, 400, 16px, 0.035px, var(--cg-grey-800), null, true);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            max-height: 48px !important;
        }

        .h22 {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: calc(1.5em * 1);
            white-space: nowrap;
            word-break: break-word;
        }

        .truncate-multilinee {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5em;
            max-height: calc(1.5em * 1);
            white-space: normal;
            word-break: break-word;
            @include m-global.margin-y(5px);
        }

        .locations-section .locations-header .page-title {
            @include m-global.font(32px, 500, 36px, 0.48px, null, null, true);
        }

        .offer-section .offer-cards {
            @include m-global.grid(true, 1, 1fr);
            @include m-global.flex-gap(15px, center, center, column);
            margin: 0 auto;
            width: 100%;
        }

        .offer-section .offer-cards .offer-card {
            width: 100%;
            max-width: 100%;
            min-width: 0;
            height: 72px;
        }
    }

    @media (max-width: 700px) {
        .image-container-right {

            .image-wrapper {
                height: 132px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .location-card-image-right {
                width: 100%;
                object-fit: cover;
            }
        }

        #page-content-image img {
            @include m-global.size(100%, 132px);
            max-height: 132px;
        }

        .locations-section .locations-list .location-card .image-container #page-content-image img {
            @include m-global.size(100%, 132px, true);
            object-fit: cover;
            border-radius: 4px;
        }

        .offer-section .offer-cards .offer-card .offer-card-description {
            padding-right: 50px;
        }

        .offer-section {
            background-color: var(--cg-grey-100);
            @include m-global.flex(column, flex-start, true);
            @include m-global.margin-y(40px);
            gap: 40px;
        }

        .locations-section {
            padding-bottom: 40px !important;
        }

        .locations-section .locations-list {
            @include m-global.flex(column, flex-start, true);
            gap: 20px;
        }

        .locations-section .locations-list .location-card {
            @include m-global.size(100%, 456px, true);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 12px;
            background: var(--cg-grey-100);
            row-gap: 10px;
            overflow: hidden;
            cursor: pointer;
        }

        .locations-section .locations-list .location-card .image-container,

        .locations-section .locations-list .location-card .image-container-right {
            width: 100%;
            height: 132px;
            overflow: hidden;
            display: block;
        }

        .locations-section .locations-list .location-card .image-container img,

        .locations-section .locations-list .location-card .image-container-right img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }

        .locations-section .locations-list .location-card .location-card-content {
            @include m-global.flex(column, flex-start, true);
            text-align: left;
            gap: 12px;
            max-width: 100%;
            overflow: visible;
        }

        .locations-section .locations-list .location-card .location-card-content h2 {
            @include m-global.font(20px, 500, 24px, null, var(--cg-grey-1100), null, true);
            margin: 0;
            margin-top: 8px !important;
        }

        .locations-section .locations-list .location-card .location-card-content .location-card-description {
            align-self: stretch;
            @include m-global.font(14px, 400, 16px, 0.035px, var(--cg-grey-800), null, true);
            margin: 0;
            max-height: 48px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal;
        }

        .locations-section .locations-list .location-card .location-card-content .location-card-hours {
            @include m-global.font(14px, 300, 16px, null, var(--cg-grey-800), null, true);
            margin-top: 10px !important;
        }

        .locations-section .locations-header {
            padding-top: 40px !important;
            padding-top: 40px !important;
        }

        .offer-card-arrow {
            margin-left: 16px;
        }
    }

    @media (max-width: 1550px) and (min-width: 768px) {

        .locations-section .locations-header {
            @include m-global.flex(column, flex-start, true);
            margin: 0 auto;
            @include m-global.size(770px, auto, true);
            max-width: calc(100% - 40px);
        }

        .locations-section .locations-header .page-title {
            text-align: left;
            align-self: stretch;
            width: 100%;
            margin: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .locations-section .locations-header .page-description {
            text-align: left;
            align-self: stretch;
            width: 100%;
            margin: 0;
        }

        .locations-section .locations-list {
            @include m-global.flex(column, center, true);
            gap: 20px;
        }

        .locations-section .locations-list .location-card {
            @include m-global.size(770px, auto, true);
            margin: 0 auto;
        }
    }

    @media (max-width: 1550px) and (min-width: 768px) {
        .offer-section {
            @include m-global.flex(column, flex-start, true);
            gap: 40px;

            .offer-header {
                @include m-global.flex(column, flex-start, true);
                margin: 0 auto;
                @include m-global.size(770px, auto, true);
            }

            .offer-title {
                @include m-global.font(40px, 700, 48px, 1.6px, var(--cx-secondary-600), null);
                font-family: Roboto, sans-serif;
            }

            .offer-description {
                @include m-global.font(16px, 400, 32px, 0.24px, var(--cg-grey-1000), null, true);
                font-family: Roboto, sans-serif;
            }

            .offer-cards {
                @include m-global.flex(column, center);
                @include m-global.flex-gap(20px, center, center, column);
                @include m-global.container-fluid(770px);
                margin: 0 auto;

                .offer-card {
                    @include m-global.size(770px, 72px);
                    margin: 0 auto;
                }
            }
        }
    }
}