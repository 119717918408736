@import 'styles/variables';
@import 'styles/legal-page-style';

#tos {

    .text-content:not(:last-child)::after {
        content: "";
        display: block;
        width: 100%;
        margin: 0px auto;
        height: 0px !important;
        background-color: rgb(231, 231, 231);
    }

    .legal-page-skeleton {
        border-top: unset !important;
    }

    &.skeleton-policy::after {
        background-color: unset !important;
    }

    &.skeleton-policy {

        .header {
            border-block: none;
        }

        .tos-body {
            border-top: none !important;
        }
    }

    .text-content-first {
        height: 25px;
        width: 280px;
        display: flex;
        justify-content: flex-end;
        margin: 0 auto;
        position: relative;
        margin-right: 0px;
    }

    .skeleton-loading {
        display: flex;
        flex-direction: column;
        margin-top: $regularPad;
        background-color: $ghost-white !important;

        @media (max-width: $desktop) {
            margin-top: 25px;
        }



        &.title-tos {
            width: 260px;
            height: 36px;

            @media (max-width: 1400px) {
                margin-left: auto;
                position: relative;
                left: -250px;
                width: 100%;
                margin-bottom: unset;
            }

            @media (max-width: 800px) {
                margin-left: 0px;
                position: relative;
                left: 20px;
                width: 100%;
                margin-bottom: unset;
            }

            &.skeleton-placeholder {
                border-radius: 4px;
                width: 260px;
                height: 36px;
            }
        }


        &.contact-title {
            width: 350px;
            height: 30px;

            @media (max-width: 356px) {
                width: 300px;
            }
        }

        &.services-content {
            width: 763px;
            height: 128px;
        }

        &.summary-content {
            width: 763px;
            height: 892px;
        }

        &.definittions-content {
            width: 763px;
            height: 896px;
        }

        &.registration-content {
            width: 763px;
            height: 348px;
        }

        &.use-service-content {
            width: 763px;
            height: 628px;
        }

        &.payment-content {
            width: 763px;
            height: 624px;
        }

        &.transactions-content {
            width: 763px;
            height: 624px;
        }

        &.disputes-content {
            width: 763px;
            height: 764px;
        }

        &.disputes-user-content {
            width: 763px;
            height: 2580px;
        }

        &.third-party-content {
            width: 763px;
            height: 468px;
        }

        &.license-content {
            width: 763px;
            height: 616px;
        }

        &.intellectual-content {
            width: 763px;
            height: 464px;
        }

        &.disclosure-content {
            width: 763px;
            height: 476px;
        }

        &.confidentiality-content {
            width: 763px;
            height: 788px;
        }

        &.indemification-content {
            width: 763px;
            height: 424px;
        }

        &.communications-content {
            width: 763px;
            height: 160px;
        }

        &.posted-content {
            width: 763px;
            height: 160px;
        }

        &.liability-content {
            width: 763px;
            height: 1632px;
        }

        &.warranties-content {
            width: 763px;
            height: 1216px;
        }

        &.agreement-content {
            width: 763px;
            height: 320px;
        }

        &.assigment-content {
            width: 763px;
            height: 128px;
        }

        &.waiver-content {
            width: 763px;
            height: 96px;
        }

        &.majeure-content {
            width: 763px;
            height: 160px;
        }

        &.governing-content {
            width: 763px;
            height: 1476px;
        }

        &.contact-content {
            padding: $smallPad;
            font-size: $regular;
            margin-bottom: $mediumPad;
            color: $secondarycolor;
            background: $light-gray-border;

            @media (max-width: 820px) {
                width: auto;
            }
        }

        @media (max-width: 820px) {
            &.content-1 {
                height: 160px;
            }
        }

        @media (max-width: $phone) {
            &.contact-title {
                font-size: $small;
            }

            &.contact-content {
                font-size: $small;
            }
        }
    }

    .summary p {
        margin-bottom: $extrahugetitle !important;
    }
}