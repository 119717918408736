@import 'styles/variables';

#purchase-flow {
    padding-top: 154px;
    padding-bottom: 100px;

    @media (max-width: $tablet) {
        padding-top: 90px;
        padding-bottom: 50px;
    }

    @media (max-width: $phone) {
        padding-top: 72px;
        padding-bottom: 40px;
    }

    >div.container {
        max-width: 922px;
        padding-left: 0;
        padding-right: 0;

        @media (max-width: $desktop) {
            max-width: 728px;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .back {
        color: $purple-text;
        cursor: pointer;
    }

    .circle-icon {
        height: 20px;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 20px;
    }

    .box {
        background: $white;
        border-radius: 12px;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
        padding: 40px 20px;

        @media (max-width: $tablet) {
            padding: 20px;
        }

        @media (max-width: $phone) {
            padding: 20px 15px;
        }
    }

    .payment-top {
        padding-top: 20px;
    }

    .summary-content-grid-row {
        letter-spacing: 0px !important;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        height: 50px;
        padding: 7px 0px;
        border-bottom: solid 1px $divisor-gray-light;
    }

    .payment-component {
        display: block;
        min-width: 300px;
    }

    #document-upload, .wallet-address {
        border-top: 1px solid $divisor-gray-light  !important;
    }

    .veriff-btn {
        height: 50px !important;
        padding: 0 50px;
    }

    .recipient {
        align-items: center;

        & img {
            max-width: 21px;
            margin-right: 7px;
        }
    }

    //General Class
    .purchase-flow-spacing {
        margin-top: 30px;

        @media (max-width: $phone) {
            margin-top: 20px;
        }
    }

    .grid-justify-self-end {
        justify-self: end;
    }

    .text-grey {
        color: rgba(86, 89, 116, 1);
    }

    .mail-icon {
        font-size: $extralarge;
        margin-right: 10px;
    }

    .box-container {
        margin-top: 20px;
    }

    .date-box {
        width: 160px;
        height: 110px;
        background-color: $white;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        margin-right: 20px;
        padding: 15px 0;
        color: $darkergray;

        .title {
            font-size: $smallplus;
            margin-bottom: 8px;
        }

        .sub-title { 
            font-size: $extra-tiny;
        }

        &:hover {
            border: solid 1px $purple-text;
            cursor: pointer;
        }
    }

    .box-icon {
        margin-bottom: 10px;
    }

    .icon-selected {
        filter: brightness(0) invert(1);
    }

    .box-selected {
        background: linear-gradient(90.12deg, #4300A9 2.45%, #00338C 101.9%);
        color: $white;
    }
    
    @media (max-width: $phone) {

        .payment-top {
            grid-auto-flow: row;
            height: auto;

            .grid-justify-self-end {
                justify-self: unset;
            }
        }

        .payment-component {
            display: block;
            width: 100%;
        }

        .date-box {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    .payment-crypto-grid {
        display: grid;
        grid-auto-flow: column;
        column-gap: 20px;
    }

    //MD OVerride should be removed later to Wallet component
    .mdc-line-ripple {
        display: none;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 20px;
        top: 11px;
    }

    .mdc-text-field__input,
    #phone-number-input {
        @media (max-width: $phone) {
            font-size: $small;            
        }
    }

    .loading-container {
        width: 203px;
    }

    .back-button {
        margin-bottom: 20px;

        @media (max-width: $phone) {
            margin-bottom: 15px;
        }

    }

    .phone-container {
        & .phone-number-container,
        & .code-verification-container {
            width: 428px;

            @media (max-width: $desktop) {
                width: 100%;               
            }
        }

        & .copy-text-bellow {
            max-width: 805px;
            padding-right: 0;
            line-height: $medium;
            letter-spacing: 0.32px;
            font-size: $regular;
            font-weight: 300;

            @media (max-width: $desktop) {
                font-size: $small;
                line-height: $regular;                
            }
        }

        & .code-verification-container ~ .row .copy-text-bellow {            
            margin-top: 12px;
        }
        
        & .phone-number-container ~ .copy-text-bellow {            
            margin-top: 20px;            
        }
    }

    #cx-progress-bar {
        margin-top: 13px;
        margin-bottom: 44px;

        @media (max-width: $tablet) {
            margin-top: -36px;
        }

        @media (max-width: $phone) {
            margin-top: -54px;
            margin-bottom: 34px;
        }
    }

    .tooltip-container {
        position: relative;

        &:hover {
            .tooltip-detail {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .tooltip-detail {
        position: absolute;
        top: 0;
        left: calc(100% + 10px);
        width: 162px;
        background-color: $purple-text;
        font-weight: 300;
        border-radius: 4px;
        padding: 3px 7px;
        display: none;
        line-break: anywhere;

        .caption {
            color: $white !important;
        }

        &::after {
            content: "";
            position: absolute;
            right: 100%;
            margin-top: 4px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent $purple-text transparent transparent;
          }

        @media (max-width: $desktop) {
            top: 26px;
            left: -10px;

            &::after {
                bottom: 100%;
                left: 16px;
                border-color: transparent transparent $purple-text transparent;
            }
        }
    }

    .caption-icon {
        margin-right: 10px;
    }

    .purchase-flow-expandable {
        padding-left: 0 !important;
        box-shadow: none !important;
    
        & .mdc-expandable__header {
            border-top: 1px $divisor-gray-light solid;
            padding: 0;
    
            &.mdc-expandable__header--closed,
            &.mdc-expandable__header--open {
                height: 64px !important;
                border-radius: 0;
            }
    
            .mdc-expandable__dropdown-icon {
                margin: 0 5px;
            }
            
            .expandable-item-arrow {
                color: $purple-text;
                transition: all 0.2s ease;
            }
    
            &.mdc-expandable__header--closed .expandable-item-arrow {
                transform: rotate(0deg);
            }
            
            &.mdc-expandable__header--open .expandable-item-arrow {
                transform: rotate(180deg);
            }
    
            
            &.mdc-expandable__header--focused {
                border-radius: 0 !important;
            }
            
            &.mdc-ripple-upgraded--background-focused::before {
                opacity: 0 !important;
            }
    
            &::before,
            &::after {
                background-color: $transparent;
            }
        }    
    
        &.mdc-expandable--open {
            margin: 0 !important;
        }
    
        .mdc-expandable__content {
            padding: 0 0 10px 0 !important;
    
            .drawer-subitem {
                padding: 10px 40px;
            }
    
            .mdc-expandable__content-container-sub .mdc-expandable__content {
                padding: 0 !important;
            }
    
            .item-flag {
                width: 30px;
                max-height: 20px;
                margin-right: 7px;
            }
        }
    }

    .coupon-discount {
        color: $success-green;
        font-size: $small;
        font-weight: 400;

        @media (width >= $phone) {
            font-size: $regular;
        }
    }
    
    .receive-content-wrapper, 
    .recipient-tooltip {
        --cds-tooltip-width: 250px;
    }

    .previous-card {
        min-width: 267px;
        min-height: 111px;
        background-color: $palegray;
        transition: background-color 0.5s ease, border-color 0.5s ease;
        border: 1px solid $transparent;

        .static-width {
            width: 173px;
            margin-left: 10px;

            @media (width <= $desktop) {
                width: 133px;
            }

            @media (width <= $tablet) {
                width: 58.33%;
            }
        }

        &.selected {
            border: 1px solid $cx-purple;
        }

        &.expired {
            border: 1px solid $red;
        }

        &.expiring-soon {
            border: 1px solid $yellow;
        }

        .card-image {
            max-width: 45px;
            width: 42px;
        }

        .card-billing-address {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .close-icon-card {
        height: 10px;
        opacity: 0;
        transition: visibility 0.3s linear, opacity 0.3s linear;
        visibility: hidden;
        filter: brightness(0%);
        width: 10px;

        @media ($tablet <= width <= $desktop) {
            &.close-icon-card-expired {
                margin-right: 6px;
            }
        }
    }

    .previous-card:hover {
        border: 1px solid rgba(87, 98, 213, 0.4);

        .close-icon-card {
            opacity: 1;
            visibility: visible;
        }
    }

    #help-icon img {
        height: 20px;
        position: absolute;
        right: 15px;
        top: 15px;
        filter: invert(100%);
    }

    .lock-icon {
        max-height: 16px;
        max-width: 13px;
    }
}
