@use 'styles/mixins' as mix;
@use 'styles/variables' as var;
@import 'styles/animations';

#order-completed {
    padding-top: 105px;
    padding-bottom: var.$hugePad;
    background: rgba(250, 250, 255, 1);
    --cds-tooltip-width: 250px;

    @media (max-width: var.$desktop) {
        padding-top: var.$hugePad;
        padding-bottom: var.$largePad;
        background: var.$white;
    }

    @media (max-width: var.$phone) {
        padding-top: 72px;
        padding-bottom: var.$bigPad;
    }

    cds-back-button {
        margin-left: -7px;
    }

    .line-1 {
        line-height: 1;
    }

    > div.container {
        max-width: 1296px;

        @media (width > var.$desktop) {
            padding-inline: 0;
        }
    }

    .order-complete {
        @include mix.flex-gap(var.$regularPad, center, $direction: column);
    }

    .box {
        background: var.$white;
        border-radius: var.$thinborder;

        @media (width <= var.$desktop) {
           background: transparent;
        }
    }

    .order-box {
        padding-inline: var.$regularPad;
        padding-top: var.$regularPad;
        padding-bottom: 5px;
        box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.06);

        @media (max-width: var.$desktop) {
            padding-inline: 8px;
            padding-block: 0;
            box-shadow: none;
        }
    }

    .tracking-box {
        padding: 20px 20px;

        @media (max-width: var.$tablet) {
            padding: 20px;
        }

        @media (max-width: var.$phone) {
            padding: 17px;
        }
    }

    .payment-method-image {
        @include mix.square(24px);
    }

    .summary-content-grid-row,
    .summary-content-grid-row-line {
        letter-spacing: 0px !important;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
    }
    
    .summary-content-grid-row-border {
        border-bottom: solid 1px var.$divisor-gray-light !important;
    }

    .summary-content-grid-row {
        height: 50px;

        &__title,
        &__data {
            color: var.$darkergray;
            line-height: 16.41px;
        }
    }

    .summary-main-title {
        margin-top: var.$bigPad;
    }

    .summary-content-grid-row-line {
       padding-top: var.$regularPad;
    }

    .title-container {
        @include mix.flex-gap(var.$smallPad, center, $direction: column);

        > h1 {
            @include mix.font(var.$extrahugetitle, 700, 47px, 0.0128em,  var.$black);
        }

        > p {
            @include mix.font(var.$regular, 400, 14px, 0.001em, var.$darkergray);
        }
    }

    .order-id {
        @include mix.font(var.$regular, 300, 16px, 0.1px, var.$darkergray, .75);

        > span {
            font-weight: 500;
        }
    }

    .order-thanks-paragraph {
        @include mix.font(var.$regular, 400, 28px, 0.0025em, $opacity: 0.75);
        text-align: left;
    }

    .payment-component {
        display: block;
        min-width: 300px;
    }

    .wallet-address {
        border-top: 1px solid var.$divisor-gray-light !important;
    }

    .action-buttons-container {
        @include mix.flex-gap(var.$regularPad);

        @media (width <= var.$phone) {
            flex-direction: column;
        }
    }

    .summary-container {
        margin-top: var.$regularPad;
    }

    .purchase-flow-spacing {
        margin-top: var.$bigPad;

        &.info-spacing {
            @media (width <= var.$desktop) {
                margin-top: var.$regularPad;
            }
        }

        @media (max-width: var.$phone) {
            margin-top: var.$regularPad;
        }

        &.about-spacing {
            @media (width <= var.$phone) {
                margin-top: var.$bigPad;
            }
        }
    }

    .tracking-progress-spacing {
        margin-top: var.$regularPad;
    }

    .order-status {
        @include mix.flex(center, space-between);

        @media (width <= var.$phone) {
            flex-direction: column;
            @include mix.flex(flex-start, space-between, column);
        }
    }

    .pending-verification {
        border-radius: 6px;
        text-align: center;
        color: rgba(203, 164, 51, 1);

        @media (width <= var.$phone) {
            margin-top: var.$smallPad;            
        }
    }

    .about-us-content {
        line-height: 3;
    }

    .resend-receipt {
        width: 202px;
        white-space: nowrap;

        @media (max-width: var.$phone) {
            width: 100%;
            margin-bottom: 15px;
        }
    }
    
    .download-pdf {
        width: 192px;
        white-space: nowrap;

        @media (max-width: var.$phone) {
            width: 100%;
        }
    }

    .grid-justify-self-end {
        justify-self: end;
    }

    .about-us-content {
        margin-top: var.$regularPad;
    }

    //MD OVerride should be removed later to Wallet component
    .mdc-line-ripple {
        display: none;
    }

    .single-container {
        background: var.$white;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        flex: 1 1 auto;
        min-height: 124px;
        height: auto;
        padding: 41px 21px;
        flex-flow: column;
    }

    .section-header {
        font-size: var.$largeplus;
    }

    .section-subtitle {
        font-size: var.$regular;
    }

    .disabled {
        opacity: 20%;
        cursor: auto;
    }

    .input-inner-container {
        margin-top: 20px;
        width: 379px;
    }

    .input-outer-container {
        margin-left: 0;
    }

    .mini-spinner-icon {
        position: absolute;
        right: 20px;
        top: 12.5px;
    }

    .circle-icon {
        position: absolute;
        right: 24px;
        top: 17px;
    }

    .circle-icon_close {
        color: var.$yellow;
        position: absolute;
        right: 215px;
        top: 15px;
    }

    .upload-icon {
        position: absolute;
        right: -200px;
        top: 14px;
    }

    .id-verification-number {
        font-size: var.$regular;
        font-weight: bolder;
        height: 27px;
        width: 27px;
        border-radius: 50%;
        background-color: var.$cx-purple;
        color: white;
    }

    .steps-wrapper {
        justify-content: start;
        align-items: center;
    }

    .id-verification-title {
        font-size: var.$regular;
        font-weight: bold;
    }

    .h-separator {
        width: 23px;
        height: 2px;
        background-color: var.$purple-text;
    }

    .veriff-btn {
        height: 50px !important;
        padding: 0 50px;
    }

    .verification-footer {
        margin-bottom: 220px;

        @media (max-width: var.$desktop) {
            margin-top: 50px;
        }

        @media (max-width: var.$phone) {
            margin-bottom: 0px;
            margin-left: 60px;
        }
    }

    .id-verification {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    @media (max-width: var.$tablet) {
        .single-container {
            padding: 28px 20px;
            flex-flow: row;
        }

        .section-header {
            font-size: var.$medium;
        }

        .input-inner-container {
            margin-top: 0;
            width: 320px;
        }

        .input-outer-container {
            margin-left: auto;
        }
    }

    @media (max-width: var.$phone) {
        .single-container {
            padding: 19px;
            flex-flow: column;
            justify-content: center;
        }

        .input-outer-container {
            margin-left: 0;
        }

        .input-inner-container {
            margin-top: 4px;
            width: 100%;
        }

        .section-header {
            font-size: var.$medium;
        }

        .section-subtitle {
            font-size: var.$small;
        }

        .steps-wrapper {
            justify-content: start;
            align-items: flex-start;
        }

        .id-verification {
            flex-direction: column-reverse;
        }
    }

    .single-container {
        background: var.$white;
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        flex: 1 1 auto;
        min-height: 70px;
        height: auto;
        padding: 18px 25px 18px 29px;
        flex-flow: column;

        &.cursor-pointer:hover {
            outline: 1px solid var.$purplehover;

            .add-icon {
                display: block;
            }
        }

        &.cursor-pointer.active {
            outline: 1px solid var.$purple-text;

            .add-icon {
                display: block;
            }
        }

        &.update {
            .edit {
                position: absolute;
                right: 25px;
            }

            &:hover {
                .edit {
                    display: block;
                }
            }
        }
        
        &:hover {
            .checkmark {
                content: url('/static/icons/checkmark_filled.svg');
            }

            img.pending {
                content: url('/static/icons/pending_filled.svg');
            }
            
            .address {
                content: url('/static/icons/address_filled.svg');
            }
        }
    }

    .checkmark {
        height: 16px;
        width: 16px;
        z-index: 99;
        left: 24px;
        top: 19px;
        content: url('/static/icons/checkmark_unfilled.svg');

        &:has(~ .phone) {
            left: 20px;            
        }
    }

    img.pending {
        height: 16px;
        width: 16px;
        z-index: 1;
        left: 24px;
        top: 19px;
        content: url('/static/icons/pending_unfilled.svg');

        &:has(~ .phone) {
            left: 20px;            
        }
    }

    .address {
        content: url('/static/icons/address_unfilled.svg');
        margin-left: 4px;
        margin-right: 21px;
    }

    .icon-container {
        height: 35px;
    }

    .coupon-apply-amount {
        color: var.$success-green;
        font-size: var.$regular;
        font-weight: 400;
    }

    .recipient-container {
        overflow: hidden;
        width: 100%;
        text-align: right;

        div {
            text-align: end;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 40px;
        }
    }

    .tooltip-container {
        display: flex;
        position: relative;

        &:hover {
            .tooltip-detail {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .tooltip-detail {
        position: absolute;
        top: -10px;
        left: calc(100% + 10px);
        width: 300px;
        background-color: var.$purple-text;
        font-weight: 300;
        border-radius: 4px;
        padding: 3px 7px;
        line-break: auto;
        z-index: 1;
        display: none;

        .caption {
            color: var.$lightgray !important;
        }

        &::after {
            content: "";
            position: absolute;
            right: 100%;
            margin-top: 12px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent var.$purple-text transparent transparent;
          }

        @media (max-width: var.$desktop) {
            top: 26px;
            left: -50px;

            &::after {
                bottom: 100%;
                left: 56px;
                border-color: transparent transparent var.$purple-text transparent;
            }
        }
    }

    .timer {
        margin-top: var.$regularPad;
        
        @media (width >= var.$phone) {
            margin-top: 0;
            max-width: 280px;
        }
    }

    .normal-order-details {
        @include mix.flex-gap(var.$regularPad, $direction: column);
    }

    .payment-information-title {
        @include mix.flex-gap(var.$smallPad, $direction: column);

        @media (width > var.$desktop) {
            margin-bottom: var.$regularPad;
        }

        > p {
            font-size: var.$extralarge;
            line-height: 28px;
            letter-spacing: 0.007em;
        }
    }

    .about-title {
        @include mix.font(var.$extralarge, 700, 28px, 0.007em);
        margin: 0 !important;
    }

    #cx-button {
        .secondary {
            height: 100%;
            max-height: 50px;
        }

        .purple-background {
            background: linear-gradient(90.12deg, #4300A9 2.45%, #00338C 101.9%) !important;
        }

        .secondary,
        .purple-background {
            width: 294px;
            @include mix.font(var.$medium, 500, 18.75px, 0.25px);
            text-align: center;
            border-radius: 6px;

            @media (width <= var.$phone) {
                width: 100%;
                height: 50px;
            }
        }
    }

    .completed-icon {
        color: var.$success-green;
        margin-top: var.$regularPad;
        @include mix.square(120px);
    }

    .order-support-section {
        margin-top: var.$regularPad;
        margin-bottom: 15px;
        flex: 1;
        @include mix.flex-gap(var.$bigPad, center, space-between);

        @media (width <= var.$desktop) {
            gap: var.$bigPad;
        }

        @media (width <= var.$phone) {
            flex-direction: column;
            gap: var.$regularPad;
            padding-inline: var.$regularPad;
        }

        > div {
            > .email-option {
                @include mix.font(var.$medium, 500, 19px, 0.0025em, var.$gray-blue !important);
                height: fit-content;
            }

            > .help-option {
                @include mix.font(var.$medium, 500, 19px, 0.25px, var.$cx-purple);
                height: fit-content;
            }
        }

        > .support-actions-container {
            @include mix.flex-gap(var.$regularPad);

            @media (width <= var.$phone) {
                flex-direction: column;
                width: 100%;
            }

            .secondary,
            .purple-background {
                @include mix.font(var.$medium, 500, 19px, 0.152px, $important: true);
                max-width: 192px;
                text-align: center !important;

                @media (width <= var.$phone) {
                    max-width: unset;
                    margin-bottom: 0;
                }
            }
        }
    }

    .back-button-mobile-container {
        @include mix.flex(center, space-between);
        margin-bottom: var.$regularPad;
    }

    .detailed-payment-method-container {
        @include mix.flex-gap(var.$smallPad, center);
    }

    .payment-method-description-container {
        @include mix.flex-gap(5px, $direction: column);

        > .description {
            font: 400 #{var.$small}/14px var.$primaryfont;
            letter-spacing: 0.114px;
            color: var.$black;
            opacity: .75;
        }

        > .holder {
            font: 300 #{var.$regular}/16.41px var.$primaryfont;
            letter-spacing: 0.095px;
            color: var.$user-name-gray;
            opacity: .75;
        }
    }

    .breadcrumb {
        margin-bottom: 0;
    }
    
    .breadcrumb-item {
        line-height: 19px;
        font-size: 1rem;

        > a {
            color: var.$darkergray !important;
            font-weight: 300;
            font-size: 1rem;
        }

        :not(.active):hover {
            text-decoration: underline !important;
        }
    }

    .breadcrumb-item+.breadcrumb-item:before {
        content: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='9' fill='none'%3E%3Cpath stroke='%23565974' stroke-linecap='round' stroke-width='1.5' d='M0.894531 8.39453L4.78906 4.5L0.894531 0.605471'/%3E%3C/svg%3E");
    }

    .breadcrumb-item.active {
        font-weight: 500;
    }

    .user-img {
        margin: 0 !important;
    }

    .status-subs-header-width {
        width: 80px;
    }
}
