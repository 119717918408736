@import 'styles/variables';
@import 'styles/mixins';

#sitemap {
    margin-top: 13px;
    padding-top: 127px;
    min-height: 821px;

    .skeleton-loading {
        background-color: $ghost-white !important;

        &.sitemap-title {
            height: 48px;
            width: 154px;
        }

        &.search-container {
            height: 50px;

        }

        &.search-btn {
            height: 50px;
            width: auto;
            display: flex;
        }
    }

    &.skeleton-policy {
        .header {
            border: 0 !important;
        }
    }

    #cx-button button[mdc-button] {
        border-radius: 4px;
    }

    .container {
        max-width: 100%;
        padding-inline: 188px;
        margin-inline: 0;

        @media (max-width: $widescreen) {
            padding-inline: $largeplus;
        }
    }

    #cx-input-search .cx-form-control:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline {

        &__leading,
        &__notch,
        &__trailing {
            border-width: 2px !important;
        }
    }

    .empty-caption {
        width: 40%;

        &__drawer {
            width: 80%;
        }
    }

    .close-button {
        width: 100%;
        height: 100%;
        color: $purple-text;
        font-size: $small;
        font-weight: normal;
        line-height: 13px;
        letter-spacing: 0.352px;
        background-color: transparent;
        border: none;
        @include flex(center);
        padding-inline: 0;

        .close-icon {
            margin-right: 10px;
        }
    }

    .no-results-container {
        margin-top: 20px;
    }

    .header {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $purple-text;
        padding-bottom: 24px;
        margin-bottom: 0;

        h1 {
            font-weight: 800;
            margin-bottom: 0;
            height: fit-content;
            margin-top: 5px;
            font-size: $extrahugetitle;
        }

        .search-container {
            width: 300px;
            margin-top: 3px;
        }

        .button-search {
            display: none;
            height: 50px;
            width: 75px;
        }

        @media (max-width: $widescreen) {
            h1 {
                margin-top: 8px;
            }
        }

        @media (max-width: $desktop) {
            padding-bottom: 19px;

            .search-container {
                margin-top: 0;
            }

            h1 {
                margin-top: 0;
                font-size: $moreplushuge;
            }
        }

        @media (max-width: $phone) {
            flex-flow: row;
            align-items: center;
            padding-bottom: 19px;
            margin-bottom: 0;

            .search-container {
                display: none;
            }

            .button-search {
                display: block;
                margin-right: 2px;
            }

        }

    }

    .category-content {
        padding-top: 40px;

        @media (width <=1550px) {
            grid-template-columns: repeat(auto-fit, minmax(222px, 1fr)) !important;
        }

        @media (width <=$desktop) {
            grid-template-columns: repeat(4, 1fr) !important;
            row-gap: 40px !important;
            column-gap: 18px !important;
        }

        @media (width <=700px) {
            grid-template-columns: repeat(3, 1fr) !important;
            column-gap: 60px !important;
        }

        @media (width <=$phone) {
            grid-template-columns: repeat(2, 1fr) !important;
        }

        &__drawer {
            padding-top: $regularPad;
            column-gap: $regularPad !important;
        }
    }

    .sitemap-title {
        font-weight: 700;
    }

    .category-content,
    .sitemap-links-all {
        grid-template-columns: repeat(6, 1fr);
        column-gap: $bigPad;
        row-gap: $largePad;
        margin-bottom: $hugePad;
    }

    #cx-breadcrumbs ul {
        padding-left: 0;
    }

    .category-title {
        @include flex(center);
        margin-right: 77px;
    }

    .all-pages-container {
        margin-top: $regularPad;

        &:hover,
        &:hover .arrow-container {
            color: $cx-purple !important;
            opacity: 1;
        }
    }

    .sitemap-links-all {
        margin-top: 12px;

        @media (width <=$desktop) {
            grid-template-columns: repeat(4, 1fr);
            row-gap: 36px;
            column-gap: $regularPad;
        }

        @media (width <=$phone) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .selected-category-container {
        margin-top: 34px;
    }

    .category-column {
        padding: 0;
        width: fit-content;
    }

    @media (max-width: $desktop) {
        padding-top: $largePad;
        margin-top: $largePad;

        .category-title {
            margin-right: unset;
        }
    }

    @media (max-width: $phone) {
        margin-top: 19px;
        padding-top: 68px;

        .mobile-view {
            display: flex;
        }
    }

    .category-icon {
        color: $purple-text;
        margin-right: 10px;
    }

    .category-name {
        font-weight: 700;
    }

    .sitemap-links {
        display: flex;
        flex-direction: column;
        margin-top: $regularPad;
        gap: 30px;
        width: fit-content;

        @media (width <=$phone) {
            margin-top: 16px;
        }
    }

    .sitemap-link-container {
        max-width: 222px;

        @media (width <=$phone) {
            max-width: 151px;
        }
    }

    .sitemap-link {
        color: $black;
        opacity: 0.5;
        max-width: 222px;

        &:hover {
            color: $cx-purple;
            opacity: 1;
        }

        @media (width <=800px) {
            max-width: 142px;
        }
    }

    .mdc-drawer--animate,
    .mdc-drawer--closing {
        transform: translateY(-100%);
    }

    .mdc-drawer--opening {
        transform: translateY(0);
    }

    .drawer {
        height: 100%;
        width: 100%;
        z-index: 1050;

        .mdc-content {
            width: 100%;
            height: 100%;
            padding: 15px;
            display: flex;
            flex-flow: column nowrap;
        }

        .content-form {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            padding-bottom: 10px;
            border-bottom: 1px solid $purple-text;
        }
    }
}