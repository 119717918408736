@use '@material/textfield';
@import 'styles/variables';

#notifications-container-lg {
    background-color: $lightergray;
    @include flex-center;

    .inner-container {
        width: 919px;
        border-radius: 4px;
        background-color: $white;
        margin-top: 144px;
        margin-bottom: 267px;
        padding: 40px 40px 30px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        @media (max-width: $desktop) {
            width: 100%;
            margin-top: 60px;
            margin-bottom: 0;
            padding: 50px 20px;
        }

        @media (max-width: $phone) {
            padding: 40px 20px;
            margin-top: 53px;
        }
    }

    section[data-simplebar] {
        max-height: 875px !important;

        @media (max-width: $desktop) {
            max-height: 1426px !important;
        }

        @media (max-width: $phone) {
            max-height: 1595px !important;
        }
    }

    .control-spacer {
        margin-top: 40px;
        height: 50px;

        @media (max-width: $desktop) {
            margin-top: 0;
        }
    }

    .notifications-main-title {
        line-height: 25px;

        @media (max-width: $desktop) {
            margin-bottom: 15px;
        }

        @media (max-width: $phone) {
            font-size: $medium;
        }
    }

    .btn {
        padding: 15px 30px;
        height: 50px;
        line-height: 19px;
        font-size: $medium;
        border-radius: 6px;
        letter-spacing: 0.152px;

        @media (max-width: $desktop) {
            font-size: $regular;
        }

        @media (max-width: $phone) {
            font-size: $regular;
            padding: 15px 5px;
        }
    }

    .searchbar {
        height: 50px;
        margin-top: 21px;
        background-color: $inputgray;
        border: none;
        border-radius: 3px;
        box-shadow: none;

        &.cx-search-input {
            @include textfield.outline-shape-radius(3px);
        }

        @media (max-width: $desktop) {
            margin-top: 0;
        }
        
        @media (max-width: $phone) {
            & .mdc-text-field__input {
                font-size: $regular !important;
                
                &::placeholder {
                    font-size: $regular !important;
                }
            }
        }
    }

    .search-bar-container {
        @media (max-width: $desktop) {
            margin-left: 18px;
            flex-grow: 1;
        }

        @media (max-width: $phone) {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .notifications-container {
        margin-top: 23px;
        margin-right: -35px;

        @media (max-width: $desktop) {
            margin-top: 20px;
            margin-right: 0;
        }
    }

    .earlier-notifications-container {
        margin-top: 20px;
        font-size: $large;

        @media (max-width: $phone) {
            margin-top: 10px;
            font-size: $medium;
        }
    }

    .simplebar-track {
        z-index: 1 !important;
    }

    .simplebar-content {
        padding-top: 0 !important;
        padding-right: 35px !important;

        @media (max-width: $desktop) {
            padding-right: 0 !important;            
        }
    }

    .earlier-divisor {
        margin-top: 40px;
        font-size: $large;

        @media (max-width: $desktop) {
            margin-top: 20px;
            font-size: $medium;
        }

        @media (max-width: $phone) {
            margin-top: 10px;
        }
    }

    .notification-body {
        max-height: 45px;

        @media (max-width: $phone) {
            max-height: unset;
        }
    }

    .select-box {
        margin-right: 0 !important;
    }

    .delete-icon {
        font-size: $largeplus;
        padding: 0 18px;
        height: 50px;

        @media (max-width: $phone) {
            padding: 0 11px;
        }
    }

    .header-divisor {
        width: calc(100% + 40px);
        color: $divisor-gray-dark;
        opacity: 1;
        margin-left: -20px;
    }

    .new-divisor {
        font-size: $large;

        @media (max-width: $desktop) {
            font-size: $medium;
            padding: 20px 0;
        }

        @media (max-width: $phone) {
            padding: 10px 0;
        }
    }

    .new-notifications-container {
        margin-top: 20px;

        @media (max-width: $phone) {
            margin-top: 10px;
        }
    }

    .mark-read {
        width: 175px !important;
        height: 50px;

        @media (max-width: $desktop) {
            width: 163px !important;            
        }

        @media (max-width: $phone) {
            width: 113px !important;            
        }
    }    

    .delete-buttons {
        @media (min-width:$phone) and (max-width: $desktop){
            min-width: 230px;
        }
    }
}
