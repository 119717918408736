@use 'styles/variables' as var;
@use 'styles/mixins' as mix;

#home {
    margin-top: 52px;
    padding-bottom: var.$bigPad;

    @media (width >= var.$phone) {
        margin-top: var.$largePad;
        padding-bottom: var.$largePad;
    }

    @media (width >= var.$tablet) {
        margin-top: 60px;
    }

    @media (width >= var.$desktop) {
        padding-bottom: var.$hugePad;
    }

    .container {
        max-width: 1365px;

        &:has(.container-support) {
            padding: 0;
        }

        @media (width <= var.$widescreen) {
            max-width: 1140px;
        }

        @media (width <= var.$desktop) {
            max-width: 728px;
        }

        @media (width <= var.$phone) {
            max-width: 335px;
        }
    }

    .hero-container {
        height: 717px;
        max-width: 1369px;

        @media (width <= (var.$extralargescreen - 1px)) {
            max-width: 1320px;
        }

        @media (width <= var.$extrascreen) {
            max-width: calc(100vw - 60px) !important;
            margin: 0 var.$morehuge;
        }

        @media (width <= var.$desktop) {
            max-width: calc(100vw - 80px) !important;
            margin: 0 var.$extrahugetitle;
            height: 597px;
        }

        @media (width <= var.$phone) {
            max-width: calc(100vw - var.$bigPad) !important;
            margin: 0 var.$largeplus;
        }
    }

    .hero-img {
        top: -37px;
        max-width: 657px;
        height: 356px;

        @media (width <= var.$desktop) {
            display: none;
        }
    }

    .crypto-price-cards-container {
        filter: drop-shadow(0 1.61px 64.34px var.$black-low-opacity);

        cx-crypto-price-card {
            .height-margin:not(.skeleton-loading) {
                margin-left: 26px;
            }

            .material-icons {
                margin-left: -3px;

                @media (width >= var.$desktop) {
                    margin-left: 0;
                }
            }
        }

        .extra-crypto-price-card,
        cx-crypto-price-card {
            width: 257px;
        }

        > div {
            overflow: auto hidden;
            padding-inline: 19px;
            bottom: -105px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @media (width <= var.$widescreen) {
            .extra-crypto-price-card, cx-crypto-price-card {
                 width: 180px;
            }
        }

        @media (width > var.$desktop) and (width <= var.$widescreen) {
            > div {
                bottom: -105px;
            }
        }

        @media (width <= var.$desktop) {
            margin-top: 0;

            cx-crypto-price-card {
                flex: auto;
            }

            .extra-crypto-price-card {
                flex: none;
            }

            > div {
                bottom: -70px;
                padding-inline: var.$extrahugetitle;
            }

            .extra-crypto-price-card,
            cx-crypto-price-card {
                width: 199px !important;
            }
        }

        @media (width <= var.$phone) {
            padding-inline: 0px;

            .extra-crypto-price-card,
            cx-crypto-price-card {
                width: 165px !important;
            }

            > div {
                padding-inline: var.$largeplus;
                bottom: -104px;
            }
        }
    }

    .space-between-element {
        gap: 20px;

        @media (width <= var.$widescreen) {
            gap: 18px;
        }

        @media (width <= var.$desktop) {
            gap: 20px;
        }

        @media (width <= var.$phone) {
            gap: 6px
        }
    }

    .extra-crypto-price-card {
        width: 100%;
        height: 297px;
        background-color: var.$white;
        border: 1px solid var.$cx-divider;
        border-radius: var.$small;
        padding: var.$huge 43px 27px 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (width <= var.$widescreen) {
            padding: var.$largeplus var.$regularplus var.$largeplus var.$largeplusplus;
        }

        @media (width <= var.$desktop) {
            height: 242px;
            padding: var.$largeplus var.$largeplus var.$largeplus var.$largeplusplus;
        }

        @media (width <= var.$tablet) {
            width: 199px;
            padding: var.$largeplus var.$regularplus var.$largeplus var.$largeplus;
        }

        @media (width <= var.$phone) {
            padding: var.$huge 5px var.$huge var.$huge;
            width: 165px;
        }

        &:hover, &:active {
            border: 1px solid var.$purplehover;
            cursor: pointer;
        }

        &:active {
            border: 2px solid var.$purplehover;
        }
    }

    .extra-crypto-price-card-space {
        display: none;

        @media (width <= var.$desktop) {
            content: "";
            display: inline-block;
            width: var.$largeplus;
        }
    }

    .learn-more-link {
        color: var.$purple-text;
        line-height: 18.75px;
        max-width: max-content;
        font-weight: 500;

        &.skeleton {
            width: 111px;
            height: 21px;
        }

        &:hover span:first-child {
            text-decoration: underline;
        }

    }

    .dinamyc-line-height {
        line-height: 23.8px !important;
    }

    .extra-card-text {
        line-height: var.$huge;
        margin-top: var.$tiny;
        color: var.$darkergray;

        &:has(.skeleton) {
            margin-top: 38px;
            margin-bottom: 43px;
        }

        .line-1.skeleton,
        .line-2.skeleton {
            height: 21px;
        }

        .line-1.skeleton {
            width: 144px;
            margin-bottom: 9px;
        }

        .line-2.skeleton {
            width: 157px;
        }

        @media (width <= var.$widescreen) {
            margin-top: var.$tiny;
        }

        @media (width <= var.$phone) {
            margin-top: 7px;
            margin-bottom: 35px;
            font-size: var.$regular;
            line-height: 26px;
        }

        &.skeleton-loading {
            width: 178px;
            height: 95px;
        }
    }

    .learn-more-cards-container {
        margin-top: 80px;
        width: 1277px;
        gap: 60px;
        justify-content: center;

        //Remove this when working on ticket #32373
        #cx-learn-more-card .learn-more-button{
            --cds-button-width: 250px;
            --md-filled-button-container-color: #{var.$transparent};
            background: linear-gradient(90.12deg, var.$cx-purple 2.45%, var.$cx-blue 101.9%);
            border-radius: 4px;
        }

        @media (width <= var.$extrascreen) {
            max-width: 100%;

            & #cx-learn-more-card {
                margin-bottom: var.$largeplus;
            }
        }

        @media (width <= var.$tablet), (width <= var.$desktop) {
            width: 100%;
            margin-top: 60px;
            gap: 20px;

            & #cx-learn-more-card {
                margin-bottom: 0;
            }
        }

        @media (width <= var.$phone) {
            margin-top: var.$extrahugetitle;
        }

        @media (var.$quadhdscreen <= width) {
            width: 1436px;

            #cx-learn-more-card {
                width: 438px
            }
        }
    }

    .title {
        @include mix.square(fit-content);

        &.skeleton-loading {
            height: 100px;
            width: 260px;

            @media (width <= var.$desktop) {
                height: 50px;
            }
        }

        h2 {
            font-size: var.$extrahugetitle !important;
            width: 262px;
            color: var.$black;
            line-height: 47px;
            margin-bottom: var.$largeplusplus;
            font-weight: 600;

            @media (width <= var.$widescreen) {
                margin-bottom: 0;
            }

            @media (width <= var.$desktop) {
                font-size: var.$extrahuge !important;
            }

            @media (width <= var.$tablet) {
                font-size: 38px !important;
            }

            @media (width <= var.$phone) {
                font-size: var.$moreplushuge !important;
            }
        }

        p {
            font-size: var.$medium !important;
            color: var.$darkergray;
            font-family: Roboto;
            font-size: var.$largeplus;
            font-style: normal;
            font-weight: 400;
            width: 230px;
            margin-bottom: 0;

            @media (width <= var.$widescreen) {
                width: auto;
                line-height: var.$morehuge;
            }

            @media (width <= var.$phone) {
                width: 228px;
            }
        }
    }

    .description {
        font-size: var.$medium;
        color: var.$darkergray;
        font-weight: 400;
        width: 879px;
        line-height: var.$huge;
        letter-spacing: 0.25px;

        &.skeleton-loading {
            width: 847px;
            height: 112px;

            @media (width <= var.$desktop) {
                width: 688px;
                height: 140px;
            }

            @media (width <= var.$phone) {
                width: 100%;
            }
        }

        p {
            margin-bottom: 0;
        }

        @media (width <= var.$widescreen) {
            width: 100%;
        }

        @media (width <= var.$desktop) {
            p {
                width: unset;
            }
        }

        @media (width <= var.$desktop), (width <= var.$phone) {
            margin-left: 0;
            margin-top: var.$tiny;
        }
    }

    .security {
        font-size: var.$extrahugeplus;
        font-weight: 500;
        color: var.$white;
        width: 1251px;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 46px;
        line-height: 32px;

        h2 {
            font-size: var.$medium;

            @media (width >= var.$phone) {
                font-size: var.$huge;
            }
        }

        @media (var.$quadhdscreen <= width) {
            width: 1436px;
        }

        #cx-paragraph-image {
            .au-target {
                width: fit-content;
                height: fit-content;
            }

            & .first {
                width: auto;

                @media (width > var.$desktop) {
                    width: 592px;
                }
            }

            .banner-img {
                width: 456.464px;
                float: right;
                position: relative;

                @media (width <= var.$tablet) {
                    margin-top: 0px;
                }

                @media (width <= var.$desktop) {
                    min-width: 35vw;
                }

                @media (width <= var.$phone) {
                    width: 70vw;
                }
            }

            p {
                margin-right: 0px;
            }
        }

        #cx-paragraph-title {
            & > div {
                margin-bottom: 0 !important;

                &:first-child p {
                    font-size: var.$huge;
                    font-style: normal;
                    font-weight: 500;
                    line-height: var.$extrahuge;
                }

                &:last-child {
                    opacity: 1 !important;
                    width: 622px;

                    @media (width <= var.$extralargescreen) {
                        margin-top: var.$largeplus;
                        margin-bottom: 0;
                    }

                    @media (width <= var.$desktop) {
                        width: auto;
                    }

                    & p {
                        font-size: var.$medium;
                        color: rgba(253, 253, 253, 1);
                        font-weight: 400;
                        line-height: var.$moreplushuge;
                        margin-bottom: var.$regularPad;
                        width: 100%;
                    }
                }
            }
        }

        @media (width <= var.$widescreen) {
            width: auto;
        }

        @media (width <= var.$desktop) {
            width: 100%;
            flex-direction: row;
            gap: var.$largeplus;

            #cx-paragraph-image {
                & .first {
                    margin: 0;
                }
            }

            p {
                line-height: 28px;
            }
        }

        @media (width <= var.$phone) {
            flex-direction: column;
            height: auto;
            gap: var.$moreplushuge;
            align-items: normal;

            p {
                color: #fdfdfd;
                font-family: Roboto;
                font-size: var.$medium;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.25px;
                margin-bottom: -3px;
            }
        }
    }

    .container-cx {
        margin: auto;

        @media (width <= var.$extrascreen) {
            max-width: calc(100vw - var.$hugePad);
            width: unset;
        }

        @media (width <= var.$tablet) {
            display: block !important;
        }

        @media (width <= var.$phone) {
            max-width: calc(100vw - var.$bigPad);
            padding-top: 0;
        }
    }

    .container-why-chicksx {
        margin-top: 185px;

        @media (width <= var.$desktop) {
            margin-top: 130px;
            padding-inline: var.$extrahugetitle;
        }

        @media (width <= var.$phone) {
            margin-top: 145px;
            padding: 0 var.$largeplus;
        }

        .why-chicksx {
            width: 1197px;
            height: 114px;
            gap: 88px;
            margin-left: 42px;

            @media (var.$quadhdscreen <= width) {
                width: 1436px;
            }

            @media (width <= var.$widescreen) {
                width: auto;
                height: auto;
                gap: 0px;
                margin-left: 0;
            }

            @media (width <= var.$desktop) {
                padding-inline: 0;
            }
        }
    }

    .container-security {
        background: var.$cx-purple-gradient;
        margin-top: 80px;
        width: 100%;
        height: 384px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: var.$extralarge;

        @include mix.media-range(var.$phone, var.$desktop) {
            .container-text-icon {
                gap: 25px;
            }
        }

        @media (width <= var.$desktop) {
            align-items: normal;
            justify-content: normal;
            height: auto;
            margin-top: 60px;
            padding: var.$extrahugetitle 80px;
        }

        @media (width <= var.$tablet){
            padding: 60px 66px 59px var.$extraextrahuge;
        }

        @media (width <= var.$phone) {
            padding: var.$extrahugetitle;
            margin-top: var.$extrahugetitle;
            align-items: normal;
            justify-content: normal;
        }

        #cx-button {
            height: 52px;
            margin-top: 20px;

            :hover {
                background-color: var.$white-hover;
            }

            :active {
                background-color: var.$white-pressed;
                color: var.$white;
            }

            @media (width >= var.$desktop) {
                margin-top: 0;
            }
        }
    }

    .learn {
        width: 164px;
        height: 50px;
        background: var.$white;
        color: var.$purple-text;
        font-size: var.$medium;
        font-weight: 500;
        margin-top: -20px;
    }

    .container-support {
        height: 340px;
        width: 100%;

        #page-content-area {
            h1 {
                margin-bottom: var.$largeplus;
            }
        }

        > div {
            width: 1211px;
        }

        @media (width <= var.$widescreen) {
            height: unset;
            padding: var.$hugePad var.$extralarge;

            > div {
                width: 100%;
                max-width: 1100px;
            }
        }

        @media (width <= var.$desktop) {
            padding: var.$bigPad var.$hugePad 0;
        }

        @media (width <= var.$tablet) {
            padding: var.$largePad 33px 0 var.$bigPad;
        }

        @media (width <= var.$phone) {
            padding: var.$bigPad var.$bigPad 0;

            > div {
                    width: 100%;
                }

            img {
                margin-bottom: 0;
            }
        }

        @media (var.$quadhdscreen <= width) {
            > div {
                width: 1436px;
            }
        }
    }

    .support-img {
        width: 75px;
        height: 75px;

        @media (width > var.$desktop) {
            margin-right: var.$largeplus;

            &.skeleton-loading {
                padding-right: 75px;
            }
        }

        @media (width <= var.$desktop) {
            @include mix.square(var.$extrahugeplustitle);

            &.skeleton-loading {
                margin-right: 10px;
            }
        }

        @media (width <= var.$phone) {
            @include mix.square(var.$extrahugetitle);
        }

        &.skeleton-loading {
            border-radius: 100%;
        }
    }

    .support-header {
        margin-bottom: var.$largeplus;

        @media (width <= var.$tablet) {
            margin-bottom: var.$extralargeplus;
        }

        @media (width <= var.$phone) {
            margin-bottom: var.$largeplus;
        }
    }

    .home-third-title-skeleton {
        width: 622px;
        height: 150px;
        margin-bottom: 10px;
    }

    .support-title {
        h4,
        h2 {
            font-size: var.$huge;
            margin-bottom: var.$largeplus;

            @media (width <= var.$desktop) {
                min-width: 255px;
                margin-bottom: 0;
                margin-left: var.$regularplus;
                font-size: var.$huge;
            }

            @media (width <= var.$phone) {
                font-size: var.$medium;
            }
        }

        p {
            font-size: var.$medium;
            margin-bottom: var.$largeplus;
            line-height: var.$huge;

            @media (width <= var.$tablet) {
                margin-bottom: var.$largeplus;
            }

            @media (width <= var.$phone) {
                min-width: 255px;
            }
        }

        &.skeleton-loading {
            display: block;
            height: 40px;
            width: 300px;

            @media (width <= var.$phone) {
                width: 150px;
            }
        }
    }

    .skeleton-loading {
        &.support-text {
            margin-block: 20px;
            width: 950px !important;
            height: 100px !important;
        }

        &.support-security-text {
            width: 620px !important;
            height: 160px !important;
            margin-block: 20px;
        }

        &.security-skeleton-img {
            width: 440px;
            height: 222px;
            margin-left: 40px;
        }

        &.support-bottom {
            width: 164px;
            height: 50px;
        }

        @media (width <= var.$phone) {
            &.support-security-text {
                width: 100% !important;
                height: 250px !important;
            }
        }
    }

    .support {
        width: 171px;
        height: 49px;
        background: var.$cx-purple-gradient;
        color: var.$white;
        font-size: var.$medium;
        font-weight: 500;

        &:hover {
            background: var.$cx-purple-gradient-hover;
        }
    }

    .img-fluid {
        height: 356px;
    }

    .p-line-height p {
        line-height: 19px;
        font-weight: 400;

        @media (width <= var.$widescreen) {
            font-weight: 500;
            margin-top: 5px;
            font-size: var.$largeplus;
            line-height: var.$morehuge;
        }

        @media (width <= var.$phone) {
            font-size: var.$large;
            margin-top: var.$tiny;
            line-height: var.$morehuge;
            margin-bottom: 0;
        }
    }

    .margin-align-left {
        margin-left: -8px;
    }

    .learn-more-link-extra-card {
        color: var.$purple-text;
        line-height: 18.75px;
        max-width: max-content;
        font-weight: 500;

        &.skeleton {
            width: 111px;
            height: 21px;
        }

        &:hover span:first-child {
            text-decoration: underline !important;
        }

        &.skeleton-loading {
            max-width: unset;
            width: 120px;
            height: 24px;
        }
    }

    .currency-logo {
        height: 40px;
        width: 40px;

        &.skeleton-loading {
            border-radius: 100%;
        }
    }

    .seo-dropdown-content {
        margin-top: var.$bigPad;
        padding-inline: var.$regularPad;

        @media (width >= var.$phone) {
            padding-inline: var.$bigPad;
        }

        @media (width >= var.$desktop) {
            margin-top: 0;
        }

        @media (width >= var.$extrascreen) {
            padding-inline: 0;
            max-width: 1365px;
            margin-inline: auto;
        }

        .collapse-content {
            padding-block: 0 12px;

            @media (width >= var.$widescreen) {
                padding-block: var.$regularPad 32px;
            }

            h2 {
                color: var.$color-accent-1100;
            }

            h3 {
                color: var.$color-accent-1100;
            }

            p {
                color: var.$color-grey-1100;
            }

            ul, ol {
                li {
                    @include mix.font(var.$medium, 400, var.$moreplushuge, 0.015em, var.$color-grey-1100);
                }
            }
        }
    }
}
